/* eslint-disable */
// @ts-nocheck
/* eslint-enable prettier/prettier */

import "tippy.js/dist/tippy.css";
import "ckeditor5/ckeditor5-content.css";

const componentRequireContext = require.context(
  "src-untyped/components/pages",
  true,
);
const ReactRailsUJS = require("react_ujs");
// eslint-disable-next-line
ReactRailsUJS.useContext(componentRequireContext);
